<!--
 * @Description: 新增联系人
 * @Author: ChenXueLin
 * @Date: 2021-10-22 09:53:13
 * @LastEditTime: 2022-07-15 16:40:54
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 属性基础信息 start -->
          <div class="baseInfo-box">
            <div class="edit-title">联系人基础信息</div>
            <el-form
              ref="addContactForm"
              label-width="100px"
              :model="addContactForm"
              :rules="rules"
              :inline="true"
              class="editForm"
            >
              <el-form-item label="所属公司" prop="corpId">
                <e6-vr-select
                  v-model="addContactForm.corpId"
                  :data="corpEnum"
                  placeholder="所属公司"
                  title="所属公司"
                  :props="{
                    id: 'corpId',
                    label: 'corpName'
                  }"
                  @filterChange="corpHandleFilter"
                  virtual
                  clearable
                  remote
                  :is-title="true"
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="联系人姓名" prop="contactName">
                <el-input
                  v-model.trim="addContactForm.contactName"
                  placeholder="联系人姓名"
                  maxlength="32"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系人职位" prop="contactDutyText">
                <el-input
                  v-model.trim="addContactForm.contactDutyText"
                  placeholder="联系人职位"
                  maxlength="512"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系人职责" prop="contactType">
                <e6-vr-select
                  v-model="addContactForm.contactType"
                  :data="contactTypeCodeEnum"
                  placeholder="联系人职责"
                  title="联系人职责"
                  clearable
                  :props="{
                    id: 'codeValue',
                    label: 'codeName'
                  }"
                  multiple
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="出生日期" prop="birthday">
                <el-date-picker
                  v-model="addContactForm.birthday"
                  type="datetime"
                  placeholder="出生日期"
                  :picker-options="pickerOptions1"
                  value-format="timestamp"
                  popper-class="special"
                  default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="身份证号" prop="idCard">
                <el-input
                  v-model.trim="addContactForm.idCard"
                  placeholder="身份证号"
                  maxlength="50"
                ></el-input>
              </el-form-item>
              <el-form-item label="性别" prop="sex">
                <e6-vr-select
                  v-model="addContactForm.sex"
                  :data="[
                    { id: 1, label: '男' },
                    { id: 2, label: '女' }
                  ]"
                  placeholder="性别"
                  title="性别"
                  clearable
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="婚否" prop="marriage">
                <e6-vr-select
                  v-model="addContactForm.marriage"
                  :data="[
                    { id: 1, label: '未婚' },
                    { id: 2, label: '已婚' }
                  ]"
                  placeholder="婚否"
                  title="婚否"
                  clearable
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="邮箱" prop="emailAddr">
                <el-input
                  v-model.trim="addContactForm.emailAddr"
                  placeholder="邮箱"
                  maxlength="512"
                ></el-input>
              </el-form-item>
              <el-form-item label="微信号" prop="wechatid">
                <el-input
                  v-model.trim="addContactForm.wechatid"
                  placeholder="微信号"
                  maxlength="256"
                ></el-input>
              </el-form-item>
              <el-form-item label="英文名" prop="englishName">
                <el-input
                  v-model="addContactForm.englishName"
                  placeholder="英文名"
                  maxlength="32"
                ></el-input>
              </el-form-item>
              <el-form-item label="籍贯" prop="nativePlace">
                <el-input
                  v-model.trim="addContactForm.nativePlace"
                  placeholder="籍贯"
                  maxlength="128"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系电话1" prop="phone1">
                <el-input
                  v-model.trim="addContactForm.phone1"
                  placeholder="联系电话1"
                  maxlength="200"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系电话2" prop="phone2">
                <el-input
                  v-model.trim="addContactForm.phone2"
                  placeholder="联系电话2"
                  maxlength="32"
                ></el-input>
              </el-form-item>
              <el-form-item label="香港电话" prop="hkPhone">
                <el-input
                  v-model.trim="addContactForm.hkPhone"
                  placeholder="香港电话"
                  maxlength="32"
                ></el-input>
              </el-form-item>
              <el-divider></el-divider>
              <div
                v-for="(item, index) in addContactForm.addressList"
                :key="index"
              >
                <el-form-item
                  :class="{
                    mb0: index === addContactForm.addressList.length - 1
                  }"
                  :label="`常用地址${index ? index + 1 : ''}`"
                  :prop="`addressList[${index}]`"
                  :rules="addressSelectRules"
                >
                  <city
                    @selected="city => handleCitySelect(city, item)"
                    ref="citySelect"
                    :defaultCity.sync="item.name"
                    clearable
                  ></city>
                </el-form-item>
                <el-form-item
                  class="mb0"
                  label-width="0"
                  :prop="`addressList[${index}].address`"
                >
                  <el-input
                    v-model="item.address"
                    placeholder="详细地址"
                    maxlength="255"
                  ></el-input>
                </el-form-item>
                <div class="address-action">
                  <i
                    class="e6-icon-add_line"
                    title="增加一个常用地址"
                    @click="addAddressform"
                  ></i>
                  <i
                    class="e6-icon-clear_line"
                    title="删除"
                    @click="delAddressform(index)"
                    v-show="index !== 0"
                  ></i>
                </div>
              </div>
              <!-- <el-divider></el-divider>
              <el-form-item class="mb0" label="保存操作：">
                <el-button class="cancel" @click="handleClose">取消</el-button>
                <el-button type="primary" @click="handleSubmit">确定</el-button>
              </el-form-item> -->
            </el-form>
          </div>
          <!-- 联系人基础信息 end -->
          <el-divider></el-divider>
          <!-- 用户关系 暂时不做  start -->
          <!-- <div class="baseInfo-box">
            <div class="tabs-wrapper">
              <div>用户关系</div>
              <section class="operate-button">
                <div class="operate-action">
                  <i
                    class="e6-icon-add_line"
                    title="新增"
                    @click="openAddContactRelation"
                  ></i>
                  <i class="e6-icon-clear_line" title="删除"></i>
                </div>
              </section>
            </div>

            <section class="table-wrapper">
              <el-table
                border
                height="300px"
                :data="tableData"
                highlight-current-row
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="40"></el-table-column>
                <el-table-column
                  label="序号"
                  width="50"
                  fixed="left"
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>
                      {{ scope.$index + 1 }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  v-for="(column, index) in columnData"
                  :key="index"
                  :prop="column.fieldName"
                  :label="column.fieldLabel"
                  :min-width="column.width"
                  :fixed="column.fixed"
                  :align="column.align"
                  header-align="center"
                >
                </el-table-column>
              </el-table>
            </section>
            <section class="pagination-wrapper fixed-section">
              <el-pagination
                :page-size.sync="contactRelationSearchForm.pageSize"
                :current-page.sync="contactRelationSearchForm.pageIndex"
                :page-sizes="pageSizes"
                :layout="layout"
                :total="total"
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              ></el-pagination>
            </section>
          </div> -->

          <!-- 属性值  end -->
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack()">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
      </section>
    </div>
    <!-- 新增用户关系 弹框 start -->
    <!-- <el-dialog
      v-dialogDrag
      title="新增用户关系"
      :visible.sync="addPostRoleDialog"
      width="500px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="addPostRoleLoading"
      :element-loading-background="loadingBackground"
      custom-class="customer-dialog"
    >
      <el-form
        ref="relationForm"
        label-width="100px"
        :model="addPostRoleForm"
        :rules="rules"
      >
        <el-form-item label="所属公司" prop="postId">
          <e6-vr-select
            v-model="addPostRoleForm.postId"
            :data="typeList"
            placeholder="所属公司"
            title="所属公司"
            clearable
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="联系人" prop="roleIds">
          <e6-vr-select
            v-model="addPostRoleForm.roleIds"
            :data="typeList"
            multiple
            placeholder="联系人"
            title="联系人"
            clearable
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="电话" prop="remark">
          <el-input
            v-model.trim="addPostRoleForm.remark"
            placeholder="电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="关系" prop="remark">
          <el-input
            v-model.trim="addPostRoleForm.remark"
            placeholder="关系"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model.trim="addPostRoleForm.remark"
            placeholder="备注"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-dialog> -->
    <!-- 新增用户关系  end -->
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import goBack from "@/mixins/goBack";
import {
  findDownList,
  getCorpMsg,
  createContact,
  getContactDetails,
  updateContact,
  contactRelationList
} from "@/api";
import { printError } from "@/utils/util";
import {
  telAndMobileValid,
  IDNOValid,
  addressSelectvalid,
  zhCNOrEnglishValid,
  zhCNValid,
  EnglishValid
} from "@/utils/validate";
export default {
  name: "addContact",
  components: {},
  mixins: [base, listPage, listPageReszie, goBack],
  data() {
    return {
      pickerOptions1: {
        disabledDate(time) {
          let timeStamp = moment(time).valueOf();
          let nowTime = moment()
            .startOf("day")
            .valueOf();
          return timeStamp > nowTime;
        }
      },
      addPostRoleForm: {
        orderNo: "",
        typeId: "",
        secondType: "",
        pageIndex: 1,
        pageSize: 20
      },
      addPostRoleLoading: true,
      addPostRoleDialog: false,
      addContactForm: {
        corpId: "", // 所属公司
        contactName: "", //联系人姓名
        contactDutyText: "", //联系人职位
        contactType: [], //联系人职责
        birthday: "", //出生日期
        idCard: "", //身份证号码
        sex: "", //性别
        marriage: "", //婚否
        emailAddr: "", //邮箱
        wechatid: "", //微信号
        englishName: "", //英文名
        nativePlace: "", //籍贯
        phone1: "", //电话1
        phone2: "", //电话2
        hkPhone: "", //香港电话
        address: "", //详细地址
        addressList: [
          {
            provinceId: "", // 省
            provinceName: "",
            cityId: "", // 市
            cityName: "",
            areaId: "", // 区
            areaName: "",
            streetId: "", // 街道
            streetName: "",
            name: "", // 省市区字符串 主要用于校验
            address: "" // 详细地址
          }
        ] //常用地址
      }, //新建联系人Form
      corpEnum: [], // 所属公司枚举
      contactTypeCodeEnum: [], // 所属公司枚举
      jobList: [], //联系人职责下拉框
      addressInfo: {}, //地址相关信息
      contactRelationSearchForm: {
        pageSize: 20,
        pageIndex: 1
      },
      total: 0,
      columnData: [
        {
          fieldName: "chargeCode",
          display: true,
          fieldLabel: "姓名",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "invoiceName",
          display: true,
          fieldLabel: "电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "所属公司",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isDisabled",
          display: true,
          fieldLabel: "关系",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isDisabled",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      selColumnId: [], // 选中的数据
      tableData: [] // 表格数据
    };
  },
  computed: {
    // 省市区地址校验规则
    addressSelectRules() {
      return {
        validator: addressSelectvalid({
          level: 3
        }),
        trigger: ["blur", "change"]
      };
    },
    rules() {
      return {
        corpId: [
          {
            required: true,
            message: "请选择所属公司",
            trigger: ["blur", "change"]
          }
        ],
        contactName: [
          {
            required: true,
            message: "请输入联系人姓名",
            trigger: ["blur", "change"]
          },
          {
            validator: zhCNOrEnglishValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        contactDutyText: [
          {
            validator: zhCNOrEnglishValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        nativePlace: [
          {
            validator: zhCNValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        phone1: [
          {
            required: true,
            message: "请输入联系电话1",
            trigger: ["blur", "change"]
          },
          {
            validator: telAndMobileValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        phone2: [
          {
            validator: telAndMobileValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        englishName: [
          {
            validator: EnglishValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        hkPhone: [
          {
            validator: telAndMobileValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        idCard: [
          {
            validator: IDNOValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        emailAddr: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ]
      };
    }
  },
  watch: {},
  created() {
    this.initData();
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  methods: {
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.addContactForm.contactId = hasParamsRoute.params.contactId;
      if (this.addContactForm.contactId) {
        this.getContactDetails();
      } else {
        if (this.$refs.addContactForm) this.$refs.addContactForm.resetFields();
        this.addContactForm.addressList.length = 1;
      }
    },
    // 获取基础信息(枚举)
    async initData() {
      try {
        //获取枚举数据 客户类型 客户类别 客户状态 客户业务类别
        let promiseList = [findDownList(["contactTypeCode"])];
        let [findDownListRes] = await Promise.all(promiseList);
        this.contactTypeCodeEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.contactTypeCode"
        });
      } catch (error) {
        printError(error);
      }
    },
    // 获取详情
    async getContactDetails() {
      try {
        this.loading = true;
        let res = await getContactDetails({
          id: this.addContactForm.contactId
        });
        if (res.code == "OK") {
          let data = res.data;
          for (let key in data) {
            if (data[key] === null) data[key] = "";
          }
          this.addContactForm = data;
          this.corpEnum = [{ corpId: data.corpId, corpName: data.corpName }];
          this.addContactForm.contactType = this.addContactForm.contactType.map(
            item => item + ""
          );
          this.addContactForm.birthday = data.birthdayStamp * 1;
          // 地址为空时默认增加一个填写地址的form
          if (
            this.addContactForm.addressList &&
            this.addContactForm.addressList.length
          ) {
            this.addContactForm.addressList.map(item => {
              let { provinceName, cityName, areaName, streetName } = item;
              let name = "";
              name += provinceName ? provinceName : "";
              name += cityName ? cityName : "";
              name += areaName ? areaName : "";
              name += streetName ? streetName : "";
              this.$set(item, "name", name);
            });
          } else {
            this.$set(this.addContactForm, "addressList", []);
            this.addAddressform();
          }
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 创建联系人
    async createContact() {
      try {
        this.loading = true;
        let param = JSON.parse(JSON.stringify(this.addContactForm));
        param.addressList = param.addressList.filter(item => {
          return item.name || item.address;
        });
        let res = await createContact(param);
        if (res.code == "OK") {
          // this.addContactForm.contactId = res.data.id;
          this.$message.success("新增成功");
          this.handleGoBack(true);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 更新联系人
    async updateContact() {
      try {
        this.loading = true;
        let param = JSON.parse(JSON.stringify(this.addContactForm));
        param.addressList = param.addressList.filter(item => {
          return item.name || item.address;
        });
        let res = await updateContact(param);
        if (res.code == "OK") {
          this.$message.success("修改成功");
          this.handleGoBack(true);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 客户列表 远程搜索
    corpHandleFilter: _.debounce(async function(val) {
      if (val) {
        this.corpDownList(val);
        this.addContactForm.corpId = "";
      }
    }, 300),
    async corpDownList(val, enumKey = "corpEnum") {
      try {
        this.corpLoading = true;
        let corpEnumRes = await getCorpMsg({
          pageIndex: 1,
          pageSize: 200,
          corpName: val
        });
        this[enumKey] = this.getFreezeResponse(corpEnumRes, {
          path: "data.array"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.corpLoading = false;
      }
    },
    //点击确定
    handleSubmit() {
      this.$refs.addContactForm.validate(valid => {
        if (valid) {
          if (this.addContactForm.contactId) this.updateContact();
          else this.createContact();
        }
      });
    },
    // 处理城市选择
    handleCitySelect(val, item) {
      item.provinceId = val.province.code;
      item.provinceName = val.province.name;
      item.cityId = val.city.code;
      item.cityName = val.city.name;
      item.areaId = val.district.code;
      item.areaName = val.district.name;
      item.streetId = val.street.code;
      item.streetName = val.street.name;
      item.name = val.name;
    },
    // 增加一个地址
    addAddressform() {
      let target = {};
      this.$set(target, "provinceId", "");
      this.$set(target, "provinceName", "");
      this.$set(target, "cityId", "");
      this.$set(target, "cityName", "");
      this.$set(target, "areaId", "");
      this.$set(target, "areaName", "");
      this.$set(target, "streetId", "");
      this.$set(target, "streetName", "");
      this.$set(target, "address", "");
      this.addContactForm.addressList.push(target);
    },
    // 删除一个地址
    delAddressform(index) {
      this.addContactForm.addressList.splice(index, 1);
    },
    // 获取联系人关系列表
    async queryList() {
      try {
        this.loading = true;
        let res = await contactRelationList(this.contactRelationSearchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 多选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.contactId;
      });
    },
    // 打开新增联系人关系弹窗
    openAddContactRelation() {
      this.addPostRoleDialog = true;
    },
    handleClose() {}
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.address-action {
  display: inline-block;
  align-items: center;
  justify-content: center;
  i {
    height: 28px;
    margin-right: 10px;
    color: $--color-primary;
    font-size: 12px;
    line-height: 28px;
    font-style: normal;
    background-color: $--color-primary-light-8; // #daf2fb
    text-align: center;
    padding: 0 10px;
    cursor: pointer;
    border-radius: 3px;
  }
  [class^="e6-icon"] {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    padding: 0;
    font-size: 16px;
  }
}
.edit-wrapper__body .editForm .mb0 {
  margin-bottom: 0px;
}
</style>
